<template>
  <section>
    <div class="searchwrap">
       <div class="searchBar">
         <select>
           <option>1</option>
           <option>2</option>
           <option>3</option>
         </select>
       </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item active">{{ $t('searchArea.rollSet') }}</button>
        <button type="button" class="tab-item">첫충,매충 설정</button>
        <button type="button" class="tab-item">매충전 조건 설정</button>
        <button type="button" class="tab-item">첫충전 조건 설정</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <tr>
            <th rowspan="2">상세 내용</th>
            <th rowspan="2">스포츠 %</th>
            <th rowspan="2">미니게임 %</th>
            <th rowspan="2">E-스포츠 %</th>
            <th rowspan="2">키론 %</th>
            <th colspan="18">카지노</th>
            <th rowspan="2">상태</th>
            <th rowspan="2">동작</th>
          </tr>
          <tr>
            <th colspan="3">에볼루션 %</th>
            <th colspan="3">빅게이밍 %</th>
            <th colspan="3">섹시게이밍 %</th>
            <th colspan="3">아시안게이밍 %</th>
            <th colspan="2">드림게이밍 %</th>
            <th colspan="2">PP 카지노 %</th>
            <th colspan="2">호텔카지노 %</th>
          </tr>
          <tr>
            <td rowspan="4">가입 첫충전</td>
            <td rowspan="4"></td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td rowspan="4"><input type="checkbox" class="set-switch"></td>
            <td rowspan="4">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="18">슬롯</th>
          </tr>
          <tr>
            <th>슬롯-스페이드 %</th>
            <th>슬롯-PP %</th>
            <th>슬롯-하바네로 %</th>
            <th>슬롯-월드메치 %</th>
            <th>슬롯-넷앤트 %</th>
            <th>슬롯-메버릭 %</th>
            <th>슬롯-퀸스핀 %</th>
            <th>슬롯-MG %</th>
            <th>슬롯-플레이엔 고 %</th>
            <th>슬롯-YL게이밍 %</th>

            <th>슬롯-레드타이거 %</th>
            <th>YGG 드라실 %</th>
            <th>슬롯-드라군 소프트 %</th>
            <th>분고 %</th>
            <th>플레이슨 %</th>
            <th>CQ9 %</th>
            <th>플레이텍 %</th>
            <th>스카이윈드 %</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
        <table class="mainTable">
          <tr>
            <th rowspan="2">상세 내용</th>
            <th rowspan="2">스포츠 %</th>
            <th rowspan="2">미니게임 %</th>
            <th rowspan="2">E-스포츠 %</th>
            <th rowspan="2">키론 %</th>
            <th colspan="18">카지노</th>
            <th rowspan="2">상태</th>
            <th rowspan="2">동작</th>
          </tr>
          <tr>
            <th colspan="3">에볼루션 %</th>
            <th colspan="3">빅게이밍 %</th>
            <th colspan="3">섹시게이밍 %</th>
            <th colspan="3">아시안게이밍 %</th>
            <th colspan="2">드림게이밍 %</th>
            <th colspan="2">PP 카지노 %</th>
            <th colspan="2">호텔카지노 %</th>
          </tr>
          <tr>
            <td rowspan="4">첫충전</td>
            <td rowspan="4"></td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td rowspan="4"><input type="checkbox" class="set-switch"></td>
            <td rowspan="4">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="18">슬롯</th>
          </tr>
          <tr>
            <th>슬롯-스페이드 %</th>
            <th>슬롯-PP %</th>
            <th>슬롯-하바네로 %</th>
            <th>슬롯-월드메치 %</th>
            <th>슬롯-넷앤트 %</th>
            <th>슬롯-메버릭 %</th>
            <th>슬롯-퀸스핀 %</th>
            <th>슬롯-MG %</th>
            <th>슬롯-플레이엔 고 %</th>
            <th>슬롯-YL게이밍 %</th>

            <th>슬롯-레드타이거 %</th>
            <th>YGG 드라실 %</th>
            <th>슬롯-드라군 소프트 %</th>
            <th>분고 %</th>
            <th>플레이슨 %</th>
            <th>CQ9 %</th>
            <th>플레이텍 %</th>
            <th>스카이윈드 %</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
        <table class="mainTable">
          <tr>
            <th rowspan="2">상세 내용</th>
            <th rowspan="2">스포츠 %</th>
            <th rowspan="2">미니게임 %</th>
            <th rowspan="2">E-스포츠 %</th>
            <th rowspan="2">키론 %</th>
            <th colspan="18">카지노</th>
            <th rowspan="2">상태</th>
            <th rowspan="2">동작</th>
          </tr>
          <tr>
            <th colspan="3">에볼루션 %</th>
            <th colspan="3">빅게이밍 %</th>
            <th colspan="3">섹시게이밍 %</th>
            <th colspan="3">아시안게이밍 %</th>
            <th colspan="2">드림게이밍 %</th>
            <th colspan="2">PP 카지노 %</th>
            <th colspan="2">호텔카지노 %</th>
          </tr>
          <tr>
            <td rowspan="4">매충전</td>
            <td rowspan="4"></td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td rowspan="4"><input type="checkbox" class="set-switch"></td>
            <td rowspan="4">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="18">슬롯</th>
          </tr>
          <tr>
            <th>슬롯-스페이드 %</th>
            <th>슬롯-PP %</th>
            <th>슬롯-하바네로 %</th>
            <th>슬롯-월드메치 %</th>
            <th>슬롯-넷앤트 %</th>
            <th>슬롯-메버릭 %</th>
            <th>슬롯-퀸스핀 %</th>
            <th>슬롯-MG %</th>
            <th>슬롯-플레이엔 고 %</th>
            <th>슬롯-YL게이밍 %</th>

            <th>슬롯-레드타이거 %</th>
            <th>YGG 드라실 %</th>
            <th>슬롯-드라군 소프트 %</th>
            <th>분고 %</th>
            <th>플레이슨 %</th>
            <th>CQ9 %</th>
            <th>플레이텍 %</th>
            <th>스카이윈드 %</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
        <table class="mainTable">
          <tr>
            <th rowspan="2">상세 내용</th>
            <th rowspan="2">스포츠 %</th>
            <th rowspan="2">미니게임 %</th>
            <th rowspan="2">E-스포츠 %</th>
            <th rowspan="2">키론 %</th>
            <th colspan="18">카지노</th>
            <th rowspan="2">상태</th>
            <th rowspan="2">동작</th>
          </tr>
          <tr>
            <th colspan="3">에볼루션 %</th>
            <th colspan="3">빅게이밍 %</th>
            <th colspan="3">섹시게이밍 %</th>
            <th colspan="3">아시안게이밍 %</th>
            <th colspan="2">드림게이밍 %</th>
            <th colspan="2">PP 카지노 %</th>
            <th colspan="2">호텔카지노 %</th>
          </tr>
          <tr>
            <td rowspan="4">보너스 미적용 충전</td>
            <td rowspan="4"></td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td rowspan="4"><input type="checkbox" class="set-switch"></td>
            <td rowspan="4">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="18">슬롯</th>
          </tr>
          <tr>
            <th>슬롯-스페이드 %</th>
            <th>슬롯-PP %</th>
            <th>슬롯-하바네로 %</th>
            <th>슬롯-월드메치 %</th>
            <th>슬롯-넷앤트 %</th>
            <th>슬롯-메버릭 %</th>
            <th>슬롯-퀸스핀 %</th>
            <th>슬롯-MG %</th>
            <th>슬롯-플레이엔 고 %</th>
            <th>슬롯-YL게이밍 %</th>

            <th>슬롯-레드타이거 %</th>
            <th>YGG 드라실 %</th>
            <th>슬롯-드라군 소프트 %</th>
            <th>분고 %</th>
            <th>플레이슨 %</th>
            <th>CQ9 %</th>
            <th>플레이텍 %</th>
            <th>스카이윈드 %</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="main-contents-wrapper flexg30">
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>가입 첫충전 레벨 1</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>가입 첫충전 레벨 2</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>가입 첫충전 레벨 3</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>가입 첫충전 레벨 4</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>가입 첫충전 레벨 5</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="main-contents-wrapper flexg30">
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>첫충전 레벨 1</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>첫충전 레벨 2</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>첫충전 레벨 3</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>첫충전 레벨 4</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>첫충전 레벨 5</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="main-contents-wrapper flexg30">
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>미충전 레벨 1</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>미충전 레벨 2</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>미충전 레벨 3</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>미충전 레벨 4</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>미충전 레벨 5</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.max') }}</th>
            <th>퍼센트 %</th>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="main-contents-wrapper flexg30">
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>보너스 미적용 충전 레벨 1</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>보너스 미적용 충전 레벨 2</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>보너스 미적용 충전 레벨 3</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>보너스 미적용 충전 레벨 4</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper flex1125">
        <div class="sub-title">
          <h3>보너스 미적용 충전 레벨 5</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>롤링 조건 %</th>
            <th>상태</th>
          </tr>
          <tr>
            <td></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
      </div>
    </div>

    <div class="table-wrapper">
      <table class="mainTable w350">
        <tr>
          <th>조건</th>
          <th>상태</th>
        </tr>
        <tr>
          <td></td>
          <td><input type="checkbox" class="set-switch"></td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>

import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import TableHead from "@/components/main/table/Head.vue";
import BtnVisibleToggle from "@/components/ui/BtnVisibleToggle";
import { memberStatusList, memStatusChange } from "@/api/member.js";
import { replaceDateT, numberWithCommas } from "@/libs/utils.js";
import Pagination from "@/components/common/Pagination";

export default {
  name: "EventRolling",
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    BtnVisibleToggle,
    Pagination
  },
  props: {
    status: {
      type: String,
      default: "0"
    }
  },
  watch: {
    $route: {
      async handler() {
        const status = this.$route.params.status;
        console.log(status);
        this.setTableData(1, status);
      }
    }
  },
  data: function () {
    return {
      tableName: "Basic",

      headInfo: {
        fstColumn: false,
        dataList: ["memId", "memNick", "memPhone", "refundAcc", "recommenderId", "memRegDt", "regSiteDomain", "memRegIp", "statusChange"]
      },
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: false,
            opts: [
              {
                text: "id",
                value: "memId"
              },
              {
                text: "recommenderId",
                value: "recommenderId"
              }
            ]
          }
        ]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        recommenderId: "",
        memId: "",
        startDate: "",
        endDate: "",
        memStatus: "0"
      },
      pageInfo: {},
      applyList: []
    };
  },
  methods: {
    openDetail_company(pId) {
      console.log(pId);
    },
    openModal(type) {
      console.log(type);
    },
    async setTableData(pageNum, status) {
      if (pageNum) {
        this.reqData.page = pageNum;
      }
      const data = this.reqData;
      if (status) {
        this.reqData.memStatus = status;
      }
      const _status = this.reqData.memStatus;
      const refuseMemoExt = this.headInfo.dataList.indexOf('refuseMemo'); //헤더에 거절사유 존재여부 확인
      console.log(refuseMemoExt)
      const addPosition = this.headInfo.dataList.indexOf('statusChange') + 1;

      if(_status==="-1"||_status===""){
        if(refuseMemoExt === -1){
          this.headInfo.dataList.splice(addPosition,0,'refuseMemo');
        }
      }else{
        if(refuseMemoExt !== -1){
          this.headInfo.dataList.splice(addPosition,1);
        }
      }
      const res = await memberStatusList(data);
      const applyList = res.data.list;
      this.applyList = this.dataConvertor(applyList);
      const pageInfo = res.data.pageInfo;
      this.pageInfo = pageInfo;
      // console.log(pageInfo);
    },
    dataConvertor(dataList) {
      const _dataList = dataList;
      if (!dataList.length || dataList.length === 0) {
        return _dataList;
      }
      _dataList.forEach(items => {
        const memStatus = items.memStatus;
        let statusText = "";
        if (memStatus === 0) {
          statusText = "신청";
        } else if (memStatus === 1) {
          statusText = "승인";
        } else if (memStatus === 2) {
          statusText = "대기";
        } else if (memStatus === -1) {
          statusText = "거절";
        }
        if(items.redDt){
          items.redDt = replaceDateT(items.redDt);
        }

        for (const item in items) {
          if(items[item]===""){
            items[item] = "-";
          }
          switch (item) {
            case "memStatus":
            case "memPhone":
            case "bankacc":
              break;
            default:
              if (!isNaN(Number(items[item]))) {
                items[item] = numberWithCommas(items[item]);
              }
              break;
          }
        }
        // if (item !== "memStatus" || item !== "memPhone" || item !== "bankAcc") {
        //   if (!isNaN(Number(items[item]))) {
        //     items[item] = numberWithCommas(items[item]);
        //   }
        // }
      });
      return _dataList;
    },
    async changeListType(evt) {
      const target = evt.target;
      const chooseType = target.value;
      this.reqData.memStatus = chooseType;
      console.log(chooseType);
      await this.setTableData(1);
    },
    async setMemStatus(evt) {
      const target = evt.target;
      const targetStatusValue = target.value;
      const targetMemId = target.dataset.memId;
      const reqData = {
        memId: targetMemId,
        memStatus: targetStatusValue
      };
      if (targetStatusValue === "-1") {
        reqData.statusMemo = prompt("거절 사유를 입력해주세요.");
      }
      // console.log(reqData);
      try {
        const res = await memStatusChange(reqData);
        console.log(res);
        if (res.resultCode !== "0") {
          console.log(res.resultCode);
          alert("회원상태 변경에 실패했습니다. 다시 시도해주세요.");
        }
        this.setTableData();
      } catch (e) {
        console.log(e);
        alert("회원상태 변경에 실패했습니다. 다시 시도해주세요.");
      }
    }
  },
  async created() {
    this.setTableData(1);
  }
};
</script>

<style scoped>
.main-contents-wrapper {
  padding: 8px 10px;
}
.mainTable {margin-bottom: 30px;}
.sub-title {
    font-size: 1em;
    font-weight: 700;
    color: var(--main);
    height: 32px;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 0.9em;
    border-start-end-radius: 10px;
    border-start-start-radius: 10px;
}
.sub-title > h3::before {
    content: '●';
    color: #18698b;
    margin-right: 7px;
}
.w350 {width: 350px;}
.flexg30 {display: flex;gap: 30px;}
.flex1125 {flex: 1 1 25%;}
</style>
